exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-products-js": () => import("./../../../src/pages/all-products.js" /* webpackChunkName: "component---src-pages-all-products-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-buddi-menu-js": () => import("./../../../src/pages/buddi-menu.js" /* webpackChunkName: "component---src-pages-buddi-menu-js" */),
  "component---src-pages-cannabis-reviews-js": () => import("./../../../src/pages/cannabis-reviews.js" /* webpackChunkName: "component---src-pages-cannabis-reviews-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-curated-menu-js": () => import("./../../../src/pages/curated-menu.js" /* webpackChunkName: "component---src-pages-curated-menu-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legendary-nights-js": () => import("./../../../src/pages/legendary-nights.js" /* webpackChunkName: "component---src-pages-legendary-nights-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thc-delivery-weed-delivery-vancouver-js": () => import("./../../../src/pages/thc-delivery-weed-delivery-vancouver.js" /* webpackChunkName: "component---src-pages-thc-delivery-weed-delivery-vancouver-js" */),
  "component---src-pages-vancouver-location-js": () => import("./../../../src/pages/vancouver-location.js" /* webpackChunkName: "component---src-pages-vancouver-location-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/Blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-education-post-js": () => import("./../../../src/templates/Education-post.js" /* webpackChunkName: "component---src-templates-education-post-js" */),
  "component---src-templates-location-page-js": () => import("./../../../src/templates/LocationPage.js" /* webpackChunkName: "component---src-templates-location-page-js" */)
}

