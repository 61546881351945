module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9FS9N8DD4C"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://plus.dutchie.com/plus/2021-07/graphql","headers":{"Authorization":"Bearer public-eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJBUEktQ0xJRU5UIiwiZXhwIjozMzE5NTI5Mjc1NiwiaWF0IjoxNjM4MzgzOTU2LCJpc3MiOiJodHRwczovL2R1dGNoaWUuY29tIiwianRpIjoiM2NlMzg4ODQtZjYyNS00ZGYzLWJiZmUtNTg1Yjg0ODNjMTQ4IiwiZW50ZXJwcmlzZV9pZCI6ImY3ODVkNzA4LTRlNjgtNDA5Yy1iZDY2LWIwYWFiMTM4MDM0MyIsInV1aWQiOiIyN2RiY2UwNi1kOWI4LTQ4NTktOTk1OC0yNDc1NjhiOGMzOWQifQ.kFYBksZCVNSGZ1W-sbzH-fQL2jU4dY4DwHoCLipGiaU"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"disableVendorPrefixes":true,"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://thcbackend.smart-ui.pro/graphql","schema":{"requestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"perPage":100,"previewRequestConcurrency":5},"type":{"MediaItem":{"localFile":{"requestConcurrency":1,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/favicon/android-icon-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"afc5e8d6114a29ec9dd814e327f540c9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Integral CF","Integral CF Bold","Integral CF Heavy","MADE Outer Sans Light","Neumatic Compressed Bold"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://thccanada.ca"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
