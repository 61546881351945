import React, { useEffect } from "react"
import "normalize.css"
import GlobalStyles from "../../styles/GlobalStyles"
import Typography from "../../styles/Typography"
import { HeaderMenu } from "../header/HeaderMenu"
import { Footer } from "../footer/Footer"
import { AgeGate } from "../AgeGate/AgeGate"
import { ShopLink } from "../ui/shop_link/ShopLink"
import { FilledCart } from "../header/components/Cart/dummydata"
import { Helmet } from "react-helmet"

//Apollo

export const Layout = ({ children }) => {
  useEffect(() => {
    if (typeof window != "undefined") {
      setTimeout(() => {
        console.log("💧 Drip - all page js script loading...")
        var _dcq = _dcq || []
        var _dcs = _dcs || {}
        _dcs.account = "1084796"

        ;(function () {
          var dc = document.createElement("script")
          dc.type = "text/javascript"
          dc.async = true
          dc.src = "//tag.getdrip.com/1084796.js"
          var s = document.getElementsByTagName("script")[0]
          s.parentNode.insertBefore(dc, s)
        })()
        console.log("💧 Drip - done")
      }, 4000)
    }
  }, [])

  return (
    <>
      <GlobalStyles />
      <Typography />
      <AgeGate />
      <HeaderMenu cart={FilledCart} />
      <ShopLink />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export const MenuLayout = ({ children }) => {
  useEffect(() => {
    if (typeof window != "undefined") {
      console.log("💧 Drip - all page js script loading...")
      var _dcq = _dcq || []
      var _dcs = _dcs || {}
      _dcs.account = "1084796"

      ;(function () {
        var dc = document.createElement("script")
        dc.type = "text/javascript"
        dc.async = true
        dc.src = "//tag.getdrip.com/1084796.js"
        var s = document.getElementsByTagName("script")[0]
        s.parentNode.insertBefore(dc, s)
      })()
      console.log("💧 Drip - done")
    }
  }, [])

  return (
    <>
      <GlobalStyles />
      <Typography />
      <AgeGate />
      <HeaderMenu cart={FilledCart} />
      <main>{children}</main>
      <Footer />
    </>
  )
}
